<template>
  <div>
    <el-form label-width="120px">
      <el-form-item label="Class Id">
        <el-input
          :value="filterConditions.classId"
          @input="classId => $emit('setFilterCondition', { classId })"
        />
      </el-form-item>
      <el-form-item label="Instructor">
        <TeacherSelector
          :value="filterConditions.teacherId"
          @change="
            teacher => $emit('setFilterCondition', { teacherId: teacher.id })
          "
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { TeacherSelector } from "@/components/selector";

export default {
  components: {
    TeacherSelector
  },
  props: {
    filterConditions: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
