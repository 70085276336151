<template>
  <div class="container">
    <Breadcrumb />
    <Heading
      @click="
        () => (displayAttendancesStatusByText = !displayAttendancesStatusByText)
      "
      :content="$t('pageTitle.studentsAttendances')"
    ></Heading>
    <hr />
    <template v-if="isRoleAdmin()">
      <FilterConditions
        :filterConditions="filterConditions"
        @setFilterCondition="setFilterCondition"
      />
      <hr />
    </template>
    <ClassesTabs
      :classes="classes"
      :currentClassId="currentClassId"
      @changeClass="getClass"
    >
      <div v-if="attendancesIsEditMode">
        <h2>{{ this.currentClass.title }} 的學生出席資訊</h2>
        <el-button @click="closeAttendancesEditMode" type="primary">
          {{ $t("button.back") }}
        </el-button>
        <EditAttendancesExplain />
      </div>
      <div v-else class="controlButtons">
        <h2>{{ this.currentClass.title }} 的學生出席資訊</h2>
        <AddLessonLogForm @getClass="getClass" />
        <el-tooltip
          content="編輯出席表"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="editIcon" @click="openAttendancesEditMode">
            <i class="fas fa-edit action-icon" />
          </span>
        </el-tooltip>
      </div>
      <EditStudentsAttendances
        v-if="attendancesIsEditMode"
        :classId="currentClassId"
        :lessonDates="lessonDates"
        :studentsAttendances="studentsAttendances"
        :displayAttendancesStatusByText="displayAttendancesStatusByText"
        @setStudentLessonLogAttendances="
          studentLessonLogAttendance =>
            $store.commit(
              'studentAttendance/setStudentLessonLogAttendance',
              studentLessonLogAttendance
            )
        "
        @updateStudentAttendance="
          studentAttendance =>
            $store.dispatch(
              'studentAttendance/updateStudentAttendance',
              studentAttendance
            )
        "
      />
      <StudentsAttendances
        v-else
        :classId="currentClassId"
        :lessonDates="lessonDates"
        :studentsAttendances="studentsAttendances"
        :displayAttendancesStatusByText="displayAttendancesStatusByText"
      />
    </ClassesTabs>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import AddLessonLogForm from "@/views/attendance/AddLessonLogForm";
import FilterConditions from "@/components/attendance/FilterConditions";
import ClassesTabs from "@/components/attendance/ClassesTabs";
import StudentsAttendances from "@/components/attendance/StudentsAttendances";
import EditStudentsAttendances from "@/components/attendance/EditStudentsAttendances";
import EditAttendancesExplain from "@/components/attendance/EditAttendancesExplain";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.studentsAttendances")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    AddLessonLogForm,
    FilterConditions,
    ClassesTabs,
    StudentsAttendances,
    EditStudentsAttendances,
    EditAttendancesExplain
  },
  mixins: [roleMixin],
  data() {
    return {
      currentClass: {},
      filterConditions: {
        classId: "",
        teacherId: null
      },
      attendancesIsEditMode: false,
      displayAttendancesStatusByText: true
    };
  },
  computed: {
    classes() {
      const filterClassId = (theClass, filterConditions) => {
        if (filterConditions.classId) {
          return filterConditions.classId === String(theClass.id);
        } else {
          return true;
        }
      };
      const filterTeahcerId = (theClass, filterConditions) => {
        if (filterConditions.teacherId) {
          return filterConditions.teacherId === theClass.teacher_user_id;
        } else {
          return true;
        }
      };
      return this.$store.getters["studentAttendance/classes"]
        .filter(theClass => filterClassId(theClass, this.filterConditions))
        .filter(theClass => filterTeahcerId(theClass, this.filterConditions));
    },
    currentClassId() {
      return String(this.currentClass.id);
    },
    lessonDates() {
      return this.$store.getters["studentAttendance/lessonDates"];
    },
    studentsAttendances() {
      return this.$store.getters["studentAttendance/studentsAttendances"];
    },
    profile() {
      return this.$store.getters["user/getProfile"];
    }
  },
  async created() {
    await this.$store.dispatch("studentAttendance/getClasses");
    this.setFilterCondition({ teacherId: this.profile.id });
    if (this.classes.length > 0) {
      this.getClass(this.classes[0]);
      this.currentClass = this.classes[0];
    }
  },
  methods: {
    getClass(currentClass) {
      this.$store.dispatch("studentAttendance/getClass", currentClass.id);
      this.currentClass = currentClass;
    },
    setFilterCondition(filterCondition) {
      this.filterConditions = { ...this.filterConditions, ...filterCondition };
    },
    openAttendancesEditMode() {
      this.attendancesIsEditMode = true;
    },
    closeAttendancesEditMode() {
      this.attendancesIsEditMode = false;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 100%;
  padding: 0px 40px;
  margin-bottom: 20px;
}

.controlButtons {
  display: flex;
}

.controlButtons > button {
  margin: 0px 8px;
}

.classHeader {
  display: flex;
}

.editIcon {
  font-size: 26px;
}
</style>
