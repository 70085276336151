<template>
  <el-tabs
    :value="currentClassId"
    @tab-click="
      ({ name: class_id }) => $emit('changeClass', getClass(class_id))
    "
  >
    <el-tab-pane
      :key="theClass.id"
      v-for="theClass in classes"
      :label="theClass.title"
      :name="String(theClass.id)"
    >
      <slot v-if="isCurrentClass(theClass.id)" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  props: {
    currentClassId: {
      type: String,
      default: null
    },
    classes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isCurrentClass(classId) {
      return this.currentClassId === String(classId);
    },
    getClass(classId) {
      return this.classes.find(({ id }) => String(id) === classId);
    }
  }
};
</script>
